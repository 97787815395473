<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 12C11.4667 12 13.4664 9.98528 13.4664 7.5C13.4664 5.01472 11.4667 3 9 3C6.53327 3 4.53359 5.01472 4.53359 7.5C4.53359 9.98528 6.53327 12 9 12Z"
      fill="white"
    />
    <path
      d="M8.35845 12.9322C8.56979 12.9109 8.78377 12.9 9 12.9C9.21624 12.9 9.43025 12.9109 9.6416 12.9322L9.91369 14.5648L9.00003 15.25L8.08636 14.5648L8.35845 12.9322Z"
      fill="white"
    />
    <path
      d="M6.23116 13.5304C4.12986 14.5323 2.53996 16.6401 2.02862 19.211C1.83475 20.1857 2.65361 21 3.6403 21H14.3597C15.3464 21 16.1652 20.1857 15.9714 19.211C15.46 16.6401 13.8702 14.5323 11.7689 13.5304L11.9864 14.8356C12.0478 15.204 11.8988 15.5759 11.6 15.8L9.60003 17.3C9.24447 17.5667 8.75558 17.5667 8.40003 17.3L6.40003 15.8C6.10124 15.5759 5.95223 15.204 6.01363 14.8356L6.23116 13.5304Z"
      fill="white"
    />
    <path
      d="M17.5 21H20.397C21.3613 21 22.1615 20.1857 21.972 19.211C21.2698 15.5983 18.4862 12.9 15.1593 12.9C14.9932 12.9 14.8285 12.9067 14.6653 12.92C15.5942 13.7127 17.25 15.5 18 17C19.5 20 17.5 21 17.5 21Z"
      fill="white"
    />
    <path
      d="M14 11.8396C14.3692 11.9441 14.7579 12 15.1593 12C17.5699 12 19.5241 9.98528 19.5241 7.5C19.5241 5.01472 17.5699 3 15.1593 3C14.7579 3 14.3692 3.05586 14 3.16044C15.005 4.31322 15.6147 5.83608 15.6147 7.5C15.6147 9.16392 15.005 10.6868 14 11.8396Z"
      fill="white"
    />
  </svg>
</template>
  
  <script>
import { Vue } from "vue-class-component";

export default class IconPartner extends Vue {}
</script>
  